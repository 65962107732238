import React from 'react';
import { Box, Typography, IconButton } from '@mui/material';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import EmailIcon from '@mui/icons-material/Email';

function Home() {
  return (
    <Box
      sx={{
        position: 'relative',
        zIndex: 1, // Ensure content is above the background slideshow
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center', // Center content for mobile
        padding: '2rem',
        backgroundColor: 'rgba(0, 0, 0, 0.7)', // Slightly darker for better readability
        width: { xs: '90%', sm: '70%', md: '40%' }, // Responsive width
        overflowY: 'auto', // In case content overflows
        mx: 'auto', // Center the box horizontally
      }}
    >
      <Typography variant="h2" component="h1" gutterBottom sx={{ color: 'white', fontWeight: 'bold', textAlign: 'center' }}>
        Malpractice Band
      </Typography>
      <Typography variant="body1" sx={{ color: 'white', mb: 2, textAlign: 'center' }}>
        Entertaining crowds since July 2017
      </Typography>
      <Typography variant="body1" sx={{ color: 'white', mb: 2, textAlign: 'center' }}>
        We started with doctors and lawyers, our diverse repertoire spans various genres, and we specialize in getting the crowd involved and dancing!
      </Typography>
      <Typography variant="body1" sx={{ color: 'white', mb: 2, textAlign: 'center' }}>
        Based in New Jersey and Pennsylvania, we're ready to bring the party to your event!
      </Typography>
      <Typography variant="body1" sx={{ color: 'white', fontStyle: 'italic', textAlign: 'center' }}>
        Join us for a musical malpractice that's just what the doctor ordered!
      </Typography>
      
      {/* Social Media and Email Icons */}
      <Box sx={{ mt: 3, display: 'flex', justifyContent: 'center', gap: 2 }}>
        <IconButton 
          aria-label="Facebook" 
          href="https://www.facebook.com/MALPracticeShowband" 
          target="_blank"
          rel="noopener noreferrer"
          sx={{ 
            color: 'white', 
            '&:hover': { 
              backgroundColor: 'rgba(255, 255, 255, 0.1)' 
            } 
          }}
        >
          <FacebookIcon fontSize="large" />
        </IconButton>
        <IconButton 
          aria-label="Instagram" 
          href="https://www.instagram.com/malpractice_showband" 
          target="_blank"
          rel="noopener noreferrer"
          sx={{ 
            color: 'white', 
            '&:hover': { 
              backgroundColor: 'rgba(255, 255, 255, 0.1)' 
            } 
          }}
        >
          <InstagramIcon fontSize="large" />
        </IconButton>
        <IconButton 
          aria-label="Email" 
          href="mailto:scott.dessain@gmail.com"
          sx={{ 
            color: 'white', 
            '&:hover': { 
              backgroundColor: 'rgba(255, 255, 255, 0.1)' 
            } 
          }}
        >
          <EmailIcon fontSize="large" />
        </IconButton>
      </Box>
    </Box>
  );
}

export default Home;

import React, { useState } from 'react';
import { AppBar, Toolbar, IconButton, Menu, MenuItem, Typography, Box } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { Link, useLocation } from 'react-router-dom';

function Header() {
  const location = useLocation();
  const [anchorEl, setAnchorEl] = useState(null);

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const getTabValue = () => {
    switch (location.pathname) {
      case '/':
        return 0;
      case '/contact':
        return 1;
      case '/about':
        return 2;
      default:
        return 0;
    }
  };

  return (
    <AppBar position="static" color="transparent" elevation={0}>
      <Toolbar sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '20px' }}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Box
            component="img"
            src={require('../assets/malpractice_showband_icon.png')}
            alt="Malpractice Showband Logo"
            sx={{
              width: { xs: '40px', md: '50px' },
              height: { xs: '40px', md: '50px' },
              borderRadius: '50%',
              marginRight: 2,
            }}
          />
          <Typography variant="h1" component="div" sx={{ color: 'white', fontSize: '1.5rem', fontWeight: 'bold' }}>
            MALPRACTICE
          </Typography>
        </Box>
        <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
          <MenuItem component={Link} to="/" selected={getTabValue() === 0} sx={{ color: 'white' }}>
            Home
          </MenuItem>
          <MenuItem component={Link} to="/contact" selected={getTabValue() === 1} sx={{ color: 'white' }}>
            Contact
          </MenuItem>
          <MenuItem component={Link} to="/about" selected={getTabValue() === 2} sx={{ color: 'white' }}>
            About
          </MenuItem>
        </Box>
        <IconButton
          edge="end"
          color="inherit"
          aria-label="menu"
          onClick={handleMenuOpen}
          sx={{ display: { xs: 'flex', md: 'none' } }}
        >
          <MenuIcon />
        </IconButton>
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleMenuClose}
          sx={{ display: { xs: 'block', md: 'none' } }}
        >
          <MenuItem component={Link} to="/" onClick={handleMenuClose}>
            Home
          </MenuItem>
          <MenuItem component={Link} to="/contact" onClick={handleMenuClose}>
            Contact
          </MenuItem>
          <MenuItem component={Link} to="/about" onClick={handleMenuClose}>
            About
          </MenuItem>
        </Menu>
      </Toolbar>
    </AppBar>
  );
}

export default Header;

import React, { useState, useEffect } from 'react';
import { Box } from '@mui/material';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";

// Dynamic import of all images from the background_photos folder
function importAll(r) {
  return r.keys().map(r);
}

const images = importAll(require.context('../assets/background_photos', false, /\.(png|jpe?g|svg)$/));

function BackgroundSlideshow() {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [zoomPositions, setZoomPositions] = useState([]);

  useEffect(() => {
    setZoomPositions(images.map(() => ({
      x: Math.random() * 10 - 5,
      y: Math.random() * 10 - 5,
    })));
  }, []);

  const settings = {
    dots: false,
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    beforeChange: (current, next) => setCurrentSlide(next),
  };

  return (
    <Box sx={{ height: '100vh', overflow: 'hidden', position: 'fixed', width: '100%', zIndex: -1 }}>
      <Slider {...settings}>
        {images.map((image, index) => (
          <div key={index}>
            <Box
              sx={{
                height: '100vh',
                backgroundImage: `url(${image})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                transition: 'transform 5s ease-out',
                transform: currentSlide === index 
                  ? `scale(1.1) translate(${zoomPositions[index]?.x}%, ${zoomPositions[index]?.y}%)`
                  : 'scale(1) translate(0%, 0%)',
              }}
            />
          </div>
        ))}
      </Slider>
    </Box>
  );
}

export default BackgroundSlideshow;


import React from 'react';
import { Box, Typography } from '@mui/material';

function About() {
  return (
    <Box
      sx={{
        position: 'relative',
        zIndex: 1, // Ensure content is above the background slideshow
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center', // Center content for mobile
        padding: '2rem',
        backgroundColor: 'rgba(0, 0, 0, 0.7)', // Slightly darker for better readability
        width: { xs: '90%', sm: '70%', md: '40%' }, // Responsive width
        overflowY: 'auto', // In case content overflows
        mx: 'auto', // Center the box horizontally
      }}
    >
      <Typography variant="h2" component="h1" gutterBottom sx={{ color: 'white', fontWeight: 'bold', textAlign: 'center' }}>
        About Malpractice Showband
      </Typography>
      <Typography variant="body1" sx={{ color: 'white', mb: 2, textAlign: 'center' }}>
        <strong>Our Show:</strong> We like to think we bring a little bit of Las Vegas showmanship to every event. We change costumes, crack jokes, and sing our hearts out. Our show will get your guests clapping, singing along, and shimmying on the dance floor. Our passion for entertaining led to the creation of something exciting and unique, the MAL-Practice Showband.
      </Typography>
      <Typography variant="body1" sx={{ color: 'white', mb: 2, textAlign: 'center' }}>
        <strong>Our Story:</strong> Doctor, Lawyer, Indian Chief. We’ve been there, done that, and now we feel like breaking out into song (and maybe a little boogie). The MAL-Practice Showband got its name from the fact that many of our bandmates have professional careers. In our day jobs, we practice medicine, law, education, and fitness. We even have a judge in the band! However, what we really enjoy doing is performing in front of an audience and showing people a good time. We are musicians, yes, but also top professionals. So we approach every gig with an attitude tuned toward winning you over and giving your guests a thrill.
      </Typography>
    </Box>
  );
}

export default About;
